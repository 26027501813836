import { useEffect } from "react";
import { Toolbar } from "../toolbar/toolbar";
import store from "../redux/store";
import { useSelector } from "react-redux";
import MediaCard from "../home/course-item";
import { fetchShortCourses } from "../redux/shortCourseSlice";
import { fetchCourses } from "../redux/courseSlice";

export const ShortCourseScreen = () => {
  useEffect(() => {
    store.dispatch(fetchShortCourses());
  }, []);

  const { courses } = useSelector((state) => state.shortCourseSlice);

  return (
    <div className="">
      <Toolbar />
      <div className="mt-20 overflow-hidden ">
        <div className=" relative w-full z-[2] flex flex-wrap justify-center  ">
          {courses.map(
            ({
              title,
              description,
              courseId,
              thumbnail,
              totalDuration,
              courseDurationPriceResponse,
              isPublished,
              filePath,
            }) => {
              return isPublished ? (
                <MediaCard
                  totalDuration={totalDuration}
                  courseDurationPriceResponse={courseDurationPriceResponse}
                  title={title}
                  thumbnail={thumbnail}
                  description={description}
                  courseId={courseId}
                  filePath={filePath}
                />
              ) : null;
            }
          )}
        </div>
      </div>
    </div>
  );
};
