import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import "./custom-pdf-viewer.css";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";

import worker from "pdfjs-dist/build/pdf.worker.min.js";

pdfjs.GlobalWorkerOptions.workerSrc = worker;

const CustomPdfViewer = () => {
  const { url, deviceType } = useParams();
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
  const isBigScreen = useMediaQuery({ minWidth: 1824 });
  const isTablet = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isLandScape = useMediaQuery({ query: "(orientation: landscape)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

  console.log("pdf uri is ::", url);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function goToPrevPage() {
    setPageNumber((prevPageNumber) => Math.max(prevPageNumber - 1, 1));
  }

  function goToNextPage() {
    setPageNumber((prevPageNumber) => Math.min(prevPageNumber + 1, numPages));
  }

  return (
    <div className="parent-container bg-gray-800/60 h-screen p-5">
      {/* <div className="pdf-container" onContextMenu={(e) => e.preventDefault()}>
        <Document
          // file="https://d2thm7494bvft.cloudfront.net/ChapterPdf_1683636316977.pdf"
          file="https://s3.ap-south-1.amazonaws.com/output.questphysic.com/ChapterPdf_1683636316977-7.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEH4aCmFwLXNvdXRoLTEiRzBFAiEAxEJSK03uCkJ1ZsSrMOLXda%2FeobFAVOmVaat5DqU%2BFLsCIFS1HwixoSfl7kuSqALr1gKacNOiyb7Fj2r7RURj8OFWKuQCCCcQARoMNTQzNTM4NzAxNDAyIgyDqcSih8WaavKxmLMqwQKKhW9Hln0UagKGzKo6tTnf47EmIqGDkwrLAvCnkjepdG4Vxi68QwacBslR3wbasp83HrZLNApFr2ln6Ws%2BAU4f%2F1u%2B2Oi9XRv1NHFlIecj5aw473g2shbjPuPh2%2BzDDwjznJxE0rUssAf3QSLeuFUxgFd%2BYdaZlT5DZiBF1Q8G76Thu7O4053%2FeC3td6Q2Weu8hqVZfDOtDRHufNRfjRmu9XUR63i8YAzdwVwxiHuHbe%2BMB348KwcBqFP%2FdowgKeXm9e2pAYMz4zAFr%2FrcVaYThVICP7GD7pWbnsjkx%2FKF%2Bbn0lcozkneygQiURdfDSIOC%2Bqw7YzPVmB3c7w1CzHOs%2BUOT8bUO9TLaILGDrPBUf9r5Sn0turnRbIrmg%2FoLtiZGQh%2B46i6%2FCB7C%2Fvdn%2FxCCvvk4jUL0kz9ZFQsan66cSqEw3NvJswY6swIrvDENkVh2QNGfK76d7TlGCcosXNmfZ77QJbHw%2FJE%2BIE9e6cmvzJJ1LqooPjiIoZjoNouB%2FjRkwIq2JF%2ByhVHqYySns4KqD5IV85m%2FpFV97ocLeYTdQjJUpTN3PZBHkc6KXlP9It1ixAx3Tgp%2FfXDPFOwgBanudg%2FK2xebYVZ8nzEYcyx8N2K0JpWwQ0trJZussjZkNMaN%2Fvv63fL26w30ew3fILMOXv%2FZrnHKSkJ2Mx8hjwgjmX%2BDxv7jViRpPE84mOjvftvhOModJzRoRNRcAhmrKxFzxgJvY7prVfLVDgm9GgyytwAMjMEVV8YUWUXdH3wQXeQ%2BxLzvt75Tk44c1gOaHkN40nDdvjPn3mTesJgEmxz2DFxfw87hR40rmZzECEzzaCtil1fOmcob834iDQ71&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240619T151940Z&X-Amz-SignedHeaders=host&X-Amz-Expires=300&X-Amz-Credential=ASIAX5DLOMRNDOT3WV4R%2F20240619%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=5abbbcd282782ac18fca27a6964e3f6db3e1770d42671b82106686869d4905e9"
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document>
      </div>
      <div className="navigation">
        <button onClick={goToPrevPage} disabled={pageNumber <= 1}>
          Previous
        </button>
        <span>
          Page {pageNumber} of {numPages}
        </span>
        <button
          onClick={goToNextPage}
          disabled={pageNumber >= numPages}
          style={{
            background: "white",
          }}
        >
          Next
        </button>
      </div> */}

      <div className="pdf-container shadow-md rounded-lg flex justify-center item-center" onContextMenu={(e) => e.preventDefault()}>
        {/* {isDesktopOrLaptop && <Document
          file={
            `https://d2thm7494bvft.cloudfront.net/pdf/${url}`
          }
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
            width={800}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
            />
          ))}
        </Document>} */}
        {isMobile && isPortrait &&<Document
          file={
            `https://pub-05c450bead744937814917f74c0680a0.r2.dev/data/chapter_file/files/${url}`
          }
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              width={320}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
            />
          ))}
        </Document>}
        {isLandScape && <Document
          file={
            // `https://d2thm7494bvft.cloudfront.net/pdf/${url}`
            `https://pub-05c450bead744937814917f74c0680a0.r2.dev/data/chapter_file/files/${url}`
            // https://pub-05c450bead744937814917f74c0680a0.r2.dev/data/chapter_file/files/ChapterPdf_1683636316977.pdf
          }
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              width={isDesktopOrLaptop ? 800 : 520}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
            />
          ))}
        </Document>}
      </div>
    </div>
  );
};

export default CustomPdfViewer;
